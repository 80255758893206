import { Injectable } from '@angular/core';
import { createEvents, EventAttributes } from 'ics';
import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';
import moment from 'moment';
import { ParseDurationPipe } from '../pipes/parse-flight-duration.pipe';

@Injectable({
  providedIn: 'root'
})
export class IcsService {

  constructor(private notificationSvc: NotificationService,
              private parseDurationPipe: ParseDurationPipe) { }

  generateFlightEvents(order: any) {
    const events: EventAttributes[] = order.flights?.map((flight) => {
      const depDateTime = `${flight.departure.date} ${flight.departure.time}`;
      const arrDateTime = `${flight.arrival.date} ${flight.arrival.time}`;

      const depMoment = moment.tz(depDateTime, 'YYYY-MM-DD HH:mm', flight.departure.timezone);
      const arrMoment = moment.tz(arrDateTime, 'YYYY-MM-DD HH:mm', flight.arrival.timezone);

      return {
        title: `Flight ${flight.departure.city} to ${flight.arrival.city}`,
        description: flight.segments
            .map((segment, index) =>
              `Segment ${index + 1}:<br>` +
              `  Flight operated by ${segment.operatingCarrier.name}<br>` +
              `  Duration: ${this.parseDurationPipe.transform(segment.detail.duration)}<br>` +
              `  Flight Number: ${segment.flightNumber}<br>` +
              `  Class: ${segment.detail.classOfService.cabinDesignator}<br>`
            )
            .join('<br>') +
          `<br>Total Duration: ${this.parseDurationPipe.transform(flight.duration)}`,
        location: `${flight.departure.airportName}, Terminal ${flight.departure.terminalName}`,
        url: `https://web.bookingpad${environment.domain}/orders/${order.id}`,
        start: [
          depMoment.year(),
          depMoment.month() + 1,
          depMoment.date(),
          depMoment.hour(),
          depMoment.minute()
        ],
        end: [
          arrMoment.year(),
          arrMoment.month() + 1,
          arrMoment.date(),
          arrMoment.hour(),
          arrMoment.minute()
        ],
        status: 'CONFIRMED',
        organizer: { name: order.agent, email: order.agentEmail },
        attendees: order?.passengers?.map((p) => ({
          name: `${p.data.name} ${p.data.surname}`,
          email: p.data.email,
          rsvp: false,
          role: 'REQ-PARTICIPANT'
        }))
      };
    });

    return events;
  }

  downloadIcsFile(order: any) {
    const events = this.generateFlightEvents(order);

    createEvents(events, (error, value) => {
      if (error) {
        console.error('Error generating .ics file:', error);
        this.notificationSvc.error('Error', error.message);
        return;
      }

      const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${order.id}.ics`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    });
  }

}
